// Global modal component
// Default behavior: opens on render
// To customize open/close behavior, set the isOpen boolean from the parent, close with the close callback
// To prevent user from closing the modal, use noX and noClickOutside props

import { Dialog } from '@reach/dialog'
import VisuallyHidden from '@reach/visually-hidden'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'lib/framer-motion'
import { Children, cloneElement, useEffect, useState } from 'react'
import { X } from 'react-feather'

const Modal = (
  {
    open = true,
    close,
    noClickOutside,
    noX,
    children,
    padding,
    width,
    className,
  },
  props
) => {
  const [showDialog, setShowDialog] = useState(open)

  useEffect(() => {
    setShowDialog(open)
  }, [open])

  const openModal = () => setShowDialog(true)
  const closeModal = () => {
    if (close) {
      close()
    } else {
      setShowDialog(false)
    }
  }

  const widths = {
    xl: 'w-full md:w-10/12',
    lg: 'w-full md:w-7/12 xl:w-1/2',
    md: 'w-full md:w-1/2 xl:w-5/12',
    sm: 'w-full sm:w-xs',
    xs: 'max-w-sm',
  }

  const widthClass = widths[width || 'md']

  return (
    <Dialog
      isOpen={showDialog}
      className={classNames(widthClass, 'max-w-xl')}
      aria-label="modal"
      onDismiss={noClickOutside ? openModal : closeModal}
    >
      <AnimatePresence>
        <motion.div
          animate={{
            scale: [0, 1],
          }}
          transition={{ ease: 'easeOut', duration: 0.2 }}
          className={classNames(
            'bg-white shadow-lg relative rounded',
            { 'p-8 md:p-12 lg:p-16': padding },
            className
          )}
        >
          {!noX && (
            <button
              onClick={closeModal}
              className="absolute right-0 top-0 mt-4 focus:outline-none z-top"
            >
              <VisuallyHidden>Close</VisuallyHidden>
              <motion.div
                aria-hidden
                className="text-4xl pr-4"
                whileTap={{
                  scale: 0.9,
                }}
              >
                <X />
              </motion.div>
            </button>
          )}
          {
            // add props to children so they can close modal
            Children.map(children, (child) =>
              cloneElement(
                child,
                { close: closeModal, ...props },
                child.props.children
              )
            )
          }
        </motion.div>
      </AnimatePresence>
    </Dialog>
  )
}
export default Modal
